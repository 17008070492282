// TopIcons.scss
.top-icons {
    position: fixed; // Changed from absolute to fixed
    top: 25px; // Increased from 20px to move icons down
    right: 20px;
    display: flex;
    gap: 20px;
    z-index: 1004;
   

    .icon {
        font-size: 20px;
        color: var(--icon-color, #3d3534);
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: var(--hover-color, #007bff);
        }
    }
}

.toggle-sidebar-button {
    position: fixed; // Changed from absolute to fixed
    top: 20px;
    left: 20px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1004;

    @media (max-width: 768px) {
        left: 10px; // Moves the button more to the left on mobile
        top: 15px;
    }
}



