.links-overlay {
    display: none;

    @media (max-width: 768px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9; // Just below the links section
    }
}

.links-section.full-screen {
    position: absolute;
    top: 0;
    right: -300px;
    bottom: 0;
    width: 300px;
    background-color: #111;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    transform: translateX(0);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    z-index: 2;

    &.show {
        transform: translateX(0);
        opacity: 1;
        pointer-events: auto;
    }

    .links-header {
        padding: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin: 0;
            color: #fff;
        }

        .close-button {
            background: none;
            border: none;
            color: #fff;
            cursor: pointer;
            padding: 5px;
            font-size: 24px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            transition: opacity 0.2s ease;

            &:hover {
                opacity: 1;
            }
        }
    }

    .links-list {
        padding: 15px;
        overflow-y: auto;

        .link-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            padding: 10px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;

            .link-description {
                color: #fff;
                font-weight: bold;
                margin-right: 10px;
            }

            .link-icon {
                color: #4CAF50;
                font-size: 20px;
                padding: 5px;
                transition: color 0.2s ease;

                &:hover {
                    color: #45a049;
                }
            }
        }

        .no-links-message {
            color: rgba(255, 255, 255, 0.6);
            text-align: center;
            padding: 20px;
            font-style: italic;
        }
    }

    @media (max-width: 768px) {
        position: fixed;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        border-radius: 20px 20px 0 0;
        z-index: 10;

        &.show {
            transform: translateY(0);
            opacity: 1;
        }

        .links-header {
            padding: 20px;

            h3 {
                font-size: 18px;
            }

            .close-button {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 24px;
                padding: 8px;
            }
        }
    }
} 