.login {
    max-width: 400px;
    margin: 60px auto 0;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    color: #ffffff;

    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
      font-size: 28px;
    }

    .form-group {
      margin-bottom: 1rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      input {
        width: 100%;
        padding: 0.75rem;
        border: none;
        border-radius: 50px;
        font-size: 1rem;
        background-color: rgba(255, 255, 255, 0.2);
        color: #ffffff;

        &::placeholder {
          color: rgba(255, 255, 255, 0.7);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px #FFA500;
        }
      }
    }

    .error-message {
      color: #ff6b6b;
      text-align: center;
      margin-top: 1rem;
    }

    button {
      width: 100%;
      padding: 0.75rem;
      background-color: #617B64;
      color: #F5E6D3;
      border: none;
      border-radius: 50px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.2s;
      letter-spacing: 0.5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  
      &:hover {
          background-color: #095C42;
      }
    }
  }

// Add this to set a background similar to the landing page
body {
  background-image: url('../public/splash.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(101, 99, 99, 0.4);
    z-index: -1;
  }
}

.toggle-link {
  color: #F5E6D3;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
}

.forgot-password-link {
    color: #F5E6D3;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 500;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
}
