.search-box2-wrapper {
    margin-top: 1rem;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .search-box2 {
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 50px;
        width: 50%;

        &:disabled {
            background-color: #f5f5f5;
            cursor: not-allowed;
        }
    }

    .arrow-icon2 {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 0.5rem;
        min-width: 24px;
        min-height: 24px;
        position: relative;

        .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.6);
        }
    }
}
