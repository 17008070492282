.full-screen-post-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  perspective: 1000px; // Add perspective for 3D effect
}

.full-screen-post-content {
  position: relative;
  max-width: 800px;
  width: 30%;
  height: 90vh;
  background-color: #fff;
  border-radius: 10px;
  overflow: visible;
  display: flex;

  .close-button {
    display: none; // Can remove this button since we'll have clickable space
  }

  .scroll-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    &.up {
      top: 20px;
    }

    &.down {
      bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    width: 90%; // Reduce width to 90% of screen
    height: 80vh; // Reduce height to 80% of viewport height
    margin: auto; // Center the content
    border-radius: 10px; // Keep border radius on mobile
  }
}

.post-display {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2; // Ensure post is above comments
  backface-visibility: hidden; // Prevent seeing the back of the element
  transition: width 0.3s ease-in-out;
  z-index: 1;

  &.with-comments {
    width: calc(100% - 300px);
  }

  &.with-sidebar {
    width: calc(100% - 300px);
    transition: width 0.3s ease-in-out;
  }

  .media-container {
    flex: 1;
    position: relative;
    overflow: hidden;
    aspect-ratio: 1 / 1;

    .media-nav-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(64, 62, 62, 0.5);
      border: none;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      z-index: 2;
      transition: opacity 0.2s;

      &:hover {
        background: rgba(0, 0, 0, 0.7);
      }

      &.left {
        left: 10px;
      }

      &.right {
        right: 10px;
      }
    }

    .media-dots {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 4px;
      z-index: 2;
      max-width: 80%;
      flex-wrap: wrap;
      justify-content: center;

      .media-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        transition: all 0.2s;

        &.active {
          background: white;
          transform: scale(1.2);
        }

        &:hover {
          background: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }

  .post-image,
  .post-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }

  .post-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 10px;
    background: linear-gradient(to top, 
      rgba(0,0,0,0.5) 0%, 
      rgba(0,0,0,0.2) 50%, 
      rgba(0,0,0,0) 100%
    );
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    &.no-caption {
      padding-bottom: 5px;

      .post-user-info {
        margin-bottom: 10px;
      }
    }
  }

  .post-info {
    flex: 0.9;
    margin-right: 0;
  }

  .post-user-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .post-user-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }

    .post-username {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
    }
  }

  .post-caption-container {
    position: relative;
    max-width: 100%;
    padding-bottom: 10px;
  }

  .post-caption {
    color: #fff;
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .show-more-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    padding: 0;
    margin: 5px 0 0;
    text-decoration: underline;
  }

  .post-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-left: auto;
    padding-right: 0px;
  }

  .like-button,
  .comment-button,
  .link-button,
  .save-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 14px;
      margin-bottom: 2px;
    }
  }

  .link-button {
    font-size: 20px; // Slightly smaller than the other icons
    margin-top: 5px; // Add some extra space above the link button
    margin-bottom: 0; // Remove bottom margin
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.comment-section.full-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  background-color: #111;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  @media (max-width: 768px) {
    position: fixed;
    top: auto; // Change this from 'top: 0' to 'top: auto'
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    border-radius: 20px 20px 0 0;
    z-index: 2;
    z-index: 10;

    &.show {
      transform: translateY(0);
    }
  }
}

.comment-section {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  background-color: #111;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0 10px 10px 0;
  opacity: 0;
  z-index: 1;
  pointer-events: none; // Prevent interaction when hidden
  display: none;
  flex-direction: column; // Add this line

  &.show {
    opacity: 1;
    pointer-events: auto; // Allow interaction when visible
    display: flex; // Change this from 'block' to 'flex'
  }

  .comment-header {
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h3 {
      margin: 0;
      color: #fff;
      text-align: center;
    }
  }

  .comments-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;

    .comment {
      margin-bottom: 15px;

      .comment-username {
        font-weight: bold;
        margin: 0 0 5px;
        color: #fff;
        font-size: 14px;
      }

      .comment-text {
        margin: 0;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
      }
    }
  }

  .comment-form {
    display: flex;
    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    input {
      flex-grow: 1;
      margin-right: 10px;
      padding: 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    button {
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .full-screen-post-content {
    width: 90%;
    height: 70vh;
    border-radius: 10px; // Keep border radius
  }

  .post-display {
    border-radius: 10px; // Ensure the post display also has rounded corners
    
    &.with-sidebar {
      width: 100%;
    }
  }

  .media-nav-button {
    display: none !important;
  }
}

.save-button {
  margin-top: 15px;
  margin-bottom: 0;
}
