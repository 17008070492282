.product-list-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  


  .toggle-sidebar-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #3d3534;
  }

  .content-wrapper {
    padding: 30px 40px 0;
    margin-top: 0px; // Adjusted to add some space below the top section
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      padding: 10px 15px;
    }
  }

  .my-masonry-grid {
    display: flex;
    margin-left: -16px;
    width: auto;

    @media (max-width: 768px) {
      margin-left: -12px;
      width: calc(100% + 12px);
    }
  }
  
  .my-masonry-grid_column {
    padding-left: 16px;
    background-clip: padding-box;

    @media (max-width: 768px) {
      padding-left: 12px;
    }
  }

  .my-masonry-grid_column > div {
    margin-bottom: 16px;

    @media (max-width: 768px) {
      margin-bottom: 12px;
    }
  }

  &.cart-page, &.likes-page {
    .product-list {
      height: calc(100vh - 165px);
    }
  }
}

// Media query for smaller screens
@media (max-width: 768px) {
  .product-list-page {
    .content-wrapper {
      padding: 20px 20px 0;
    }
    .top-section {
      flex-direction: column;
      align-items: flex-start;
    }
    .top-icons-container {
      margin-top: 10px;
      width: 100%;
      justify-content: space-between;
    }
  }
}