/* Sidebar Styles */
.sidebar {
    position: fixed;
    top: 0;
    left: -350px;
    width: 250px;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 60px 20px 20px 20px;
    transition: left 0.3s ease-in-out;
    z-index: 1003;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    
    &.visible {
        left: 0px;
    }
    
    h2 {
        text-align: center;
        font-size: 1.5rem;
        margin: 1rem 0;
    }
    
    .conversations {
        margin-top: 20px;

        .conversation-list {
            list-style: none;
            padding: 0;
            overflow-y: auto;

            .conversation-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                margin-bottom: 15px;
                background-color: rgba(255, 255, 255, 0.34);
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 12px;
                cursor: pointer;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 165, 0, 0.1);
                transition: all 0.3s ease;

                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 0 15px rgba(255, 165, 0, 0.2);
                }

                .conversation-content {
                    flex: 1;
                    cursor: pointer;

                    .query {
                        font-size: 1.1rem;
                        font-weight: 600;
                        margin-bottom: 0.5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        transition: color 0.3s ease;

                        &:hover {
                            color: #FFA500;
                        }
                    }

                    .timestamp {
                        font-size: 0.9rem;
                        color: #666;
                    }
                }

                .action-buttons {
                    display: flex;
                    align-items: center;
                }

                .delete-button {
                    background: none;
                    border: none;
                    cursor: pointer;
                    color: #161414;
                    padding: 5px;
                    transition: color 0.3s ease;
                    font-size: 1rem;

                    &:hover {
                        color: #FFA500;
                    }
                }

                .edit-title-input {
                    width: 90%;
                    padding: 5px;
                    border: 1px solid #d4c3a9;
                    border-radius: 4px;
                    font-size: 1rem;
                    outline: none;

                    &:focus {
                        border-color: #FFA500;
                    }
                }
            }
        }
    }

    .new-search-button {
        background-color: rgba(161, 56, 222, 0.53);
        color: #ffffff;
        padding: 10px 15px;
        border: none;
        cursor: pointer;
        width: auto;
        font-size: 14px;
        text-align: center;
        border-radius: 20px;
        margin: 0 auto 20px;
        display: block;

        &:hover {
            background-color: #FFA500;
        }
    }
}