.profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    min-height: 100vh;
    position: relative;
    padding-top: 0; // Remove any top padding

    .cover-image {
        width: 100%;
        height: 200px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(101, 99, 99, 0.5); // Added translucent frosty look
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .top-icons-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }

    .profile-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -100px; // Pull the profile picture up into the cover image
        position: relative;
        z-index: 1;

        .profile-picture {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 0px;
            border: 5px solid #f9f9f9;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .change-picture-button {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease;
                opacity: 0;

                svg {
                    font-size: 20px;
                }
            }

            &:hover .change-picture-button {
                opacity: 1;
            }
        }

        .name {
            font-size: 28px;
            margin-bottom: -10px;
            font-weight: bold;
            color: #333;
        }

        .username {
            font-size: 15px;
            margin-bottom: 10px;
            font-weight: normal;
            color: #666;
        }

        .follow-stats {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            margin: 5px 0;

            .stat-item {
                display: flex;
                align-items: center;
                padding: 4px 8px;
                
                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 4px;
                }

                .count {
                    font-weight: bold;
                    margin-right: 4px;
                }
            }

            .stat-separator {
                color: #666;
            }
        }

        .bio {
            margin: 0 auto 20px auto;
            font-size: 14px;
            color: #5e5d5d;
            text-align: center;
            max-width: 200px;
            line-height: 1.4;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        .new-post-button {
            padding: 5px 10px;
            background-color: rgba(76, 175, 80, 0.1);
            color: #4CAF50;
            border: 1px solid #4CAF50;
            border-radius: 25px; // Rounded corners
            cursor: pointer;
            transition: all 0.3s ease;
            font-size: 12px;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:hover {
                background-color: rgba(76, 175, 80, 0.2);
                transform: translateY(-2px);
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
            }
        }

        .settings-container {
            position: absolute;
            top: 120px; // Positioned just below the cover image
            right: calc(50% - 580px); // Aligned with the right edge of the content
            z-index: 2; // Change this to a lower value

            .settings-button {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 20px;
                color: #333;
                transition: color 0.3s ease, transform 0.2s ease;

                &:hover {
                    color: #666;
                    transform: scale(1.1);
                }
            }

            .settings-dropdown {
                position: absolute;
                top: 100%;
                right: 0;
                background-color: white;
                border: 1px solid #ddd;
                border-radius: 4px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                overflow: hidden;

                button {
                    padding: 10px 15px;
                    background: none;
                    border: none;
                    text-align: left;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
    }

    .user-posts {
        width: 100%;
        max-width: 1200px;

        .posts-grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            padding: 20px;

            @media (min-width: 576px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (min-width: 992px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
            }

            .post-card {
                position: relative;
                overflow: hidden;
                border-radius: 12px;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease;
                cursor: pointer; // Add this line to change the cursor to a hand

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);

                    .post-overlay {
                        opacity: 1;
                    }
                }

                .post-image {
                    width: 100%;
                    height: 280px;
                    object-fit: cover;
                }

                .post-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding: 15px;
                    opacity: 0;
                    transition: opacity 0.3s ease;

                    .post-info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 10px;

                        .post-stats {
                            display: flex;
                            align-items: center;

                            .post-stat {
                                display: flex;
                                align-items: center;
                                color: white;
                                font-size: 14px;
                                margin-right: 15px;

                                svg {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .delete-post-button {
                            background: none;
                            border: none;
                            color: white;
                            cursor: pointer;
                            margin-left: auto;
                            padding: 5px;
                            transition: color 0.3s ease;

                            &:hover {
                                color: #ff4d4d;
                            }
                        }
                    }
                }

                .post-caption {
                    color: white;
                    font-size: 14px;
                    line-height: 1.4;
                    margin: 0;
                }

                .post-image,
                .post-video,
                .post-overlay {
                    cursor: pointer; // Add this to change cursor only on these elements
                }
            }
        }
    }

    .new-post-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        overflow: hidden; // Prevent overlay scroll

        .new-post-form {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            width: 90%;
            max-width: 500px;
            height: 90vh;
            max-height: 700px;
            display: flex;
            flex-direction: column;
            overflow: hidden; // Prevent form scroll
            
            @media (max-width: 768px) {
                width: 95%;
                padding: 16px;
                height: 95vh;
            }

            h2 {
                margin: 0 0 15px 0;
                font-size: 20px;
                color: #333;
            }

            form {
                display: flex;
                flex-direction: column;
                height: calc(100% - 35px);
                overflow: hidden; // Prevent form scroll

                .form-content {
                    flex: 1;
                    overflow-y: auto;
                    overflow-x: hidden; // Prevent horizontal scroll
                    padding-right: 10px;
                    
                    @media (max-width: 768px) {
                        padding-right: 0;
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                        border-radius: 3px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #888;
                        border-radius: 3px;
                    }

                    .media-preview-section {
                        margin-bottom: 20px;
                        background: #f8f8f8;
                        padding: 8px;
                        border-radius: 8px;
                    }

                    textarea {
                        width: 100%;
                        min-height: 60px;
                        max-height: 60px;
                        resize: none;
                        padding: 10px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        font-size: 14px;
                        margin: 10px 0;

                        &:focus {
                            outline: none;
                            border-color: #4CAF50;
                        }
                    }

                    .links-section {
                        margin-top: 10px;

                        h3 {
                            font-size: 14px;
                            color: #333;
                            margin-bottom: 8px;
                        }

                        .link-row {
                            margin-bottom: 8px;

                            .link-inputs {
                                display: grid;
                                grid-template-columns: 1fr 2fr auto;
                                gap: 8px;
                                align-items: center;

                                input {
                                    padding: 6px;
                                    border: 1px solid #ddd;
                                    border-radius: 4px;
                                    font-size: 13px;

                                    &:focus {
                                        outline: none;
                                        border-color: #4CAF50;
                                    }
                                }

                                .remove-link-button {
                                    padding: 6px;
                                    border: none;
                                    border-radius: 4px;
                                    background: none;
                                    color: #666;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    transition: all 0.2s;

                                    &:hover {
                                        color: #ff4d4d;
                                    }
                                }
                            }
                        }

                        .add-link-button {
                            margin-top: 8px;
                            margin-bottom: 8px;
                            padding: 6px 12px;
                            background-color: #f0f0f0;
                            border: none;
                            border-radius: 4px;
                            cursor: pointer;
                            font-size: 13px;
                            transition: background-color 0.2s;

                            &:hover {
                                background-color: #e0e0e0;
                            }
                        }
                    }
                }

                .form-buttons {
                    display: flex;
                    gap: 10px;
                    padding-top: 15px;
                    border-top: 1px solid #eee;
                    margin-top: auto;

                    button {
                        flex: 1;
                        padding: 10px;
                        border: none;
                        border-radius: 4px;
                        font-size: 14px;
                        cursor: pointer;
                        transition: all 0.2s ease;
                        min-width: 0; // Allow buttons to shrink
                        white-space: nowrap; // Prevent text wrapping

                        &.cancel-button {
                            background-color: #f0f0f0;
                            color: #666;

                            &:hover {
                                background-color: #e0e0e0;
                            }
                        }

                        &.post-button {
                            background-color: #4CAF50;
                            color: white;

                            &:hover {
                                background-color: #45a049;
                            }
                        }
                    }
                }
            }
        }
    }

    .follow-button {
        padding: 8px 16px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s;

        &:hover {
            background-color: #45a049;
        }
    }

    .settings-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        backdrop-filter: blur(5px);
    }

    .settings-modal {
        background-color: rgba(255, 255, 255, 0.9);
        padding: 25px;
        border-radius: 12px;
        width: 90%;
        max-width: 400px; // Reduced from 500px
        position: relative;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.3);

        h2 {
            margin-bottom: 15px; // Reduced from 20px
            font-size: 22px; // Reduced from 24px
            color: #333;
            text-align: center;
        }

        .close-modal {
            position: absolute;
            top: 10px; // Reduced from 15px
            right: 10px; // Reduced from 15px
            background: none;
            border: none;
            font-size: 20px; // Reduced from 24px
            cursor: pointer;
            color: #666;
            transition: color 0.3s ease;

            &:hover {
                color: #333;
            }
        }

        .form-group {
            margin-bottom: 15px; // Reduced from 20px

            label {
                display: block;
                margin-bottom: 3px; // Reduced from 5px
                font-weight: bold;
                color: #333;
                font-size: 14px; // Added to reduce label size
            }

            input {
                width: 100%;
                padding: 8px; // Reduced from 10px
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                font-size: 14px; // Reduced from 16px
                background-color: rgba(255, 255, 255, 0.8);
                transition: border-color 0.3s ease, box-shadow 0.3s ease;

                &:focus {
                    outline: none;
                    border-color: #4CAF50;
                    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
                }
            }

            textarea {
                width: 100%;
                padding: 8px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                font-size: 14px;
                background-color: rgba(255, 255, 255, 0.8);
                transition: border-color 0.3s ease, box-shadow 0.3s ease;
                resize: vertical;
                min-height: 80px;
                max-height: 160px;

                &:focus {
                    outline: none;
                    border-color: #4CAF50;
                    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
                }
            }

            .bio-character-count {
                display: block;
                text-align: right;
                font-size: 12px;
                color: #666;
                margin-top: 4px;
            }
        }

        .save-settings-button {
            background-color: #4CAF50;
            color: white;
            border: none;
            padding: 10px 15px; // Reduced from 12px 20px
            border-radius: 6px;
            font-size: 14px; // Reduced from 16px
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease;
            display: block;
            width: 100%;
            margin-top: 15px; // Reduced from 20px

            &:hover {
                background-color: #45a049;
                transform: translateY(-2px);
            }
        }

        .settings-message {
            margin-bottom: 15px; // Reduced from 20px
            padding: 8px; // Reduced from 10px
            border-radius: 6px;
            font-weight: bold;
            text-align: center;
            font-size: 14px; // Added to reduce message size

            &.success {
                background-color: rgba(212, 237, 218, 0.7);
                color: #155724;
                border: 1px solid rgba(195, 230, 203, 0.7);
            }

            &.error {
                background-color: rgba(248, 215, 218, 0.7);
                color: #721c24;
                border: 1px solid rgba(245, 198, 203, 0.7);
            }
        }
    }

    // Add responsive adjustments
    @media (max-width: 480px) {
        .settings-modal {
            width: 95%;
            padding: 20px;

            h2 {
                font-size: 20px;
            }

            .form-group {
                margin-bottom: 12px;

                input {
                    padding: 7px;
                }
            }

            .save-settings-button {
                padding: 8px 12px;
            }
        }
    }

    .profile-tabs {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin: 20px 0;
        border-bottom: 1px solid #ddd;
        width: 100%;
        max-width: 300px;

        .tab-button {
            background: none;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 14px;
            color: #666;
            position: relative;
            transition: all 0.3s ease;

            &:hover {
                color: #4CAF50;
            }

            &.active {
                color: #4CAF50;
                font-weight: 500;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: #4CAF50;
                    transition: all 0.3s ease;
                }
            }
        }
    }

    // Update the media preview section styles
    .media-preview-section {
        margin-bottom: 20px;

        .media-grid-container {
            max-height: 240px;
            overflow-y: auto;
            padding: 8px;
            background: #f8f8f8;
            border-radius: 8px;
        }

        .media-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
            min-height: 80px; // Ensure there's always space for dropping

            .media-preview-item {
                position: relative;
                aspect-ratio: 1;
                border-radius: 4px;
                overflow: hidden;
                background: #fff;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                img, video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .media-controls {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    gap: 4px;
                    padding: 4px;
                    opacity: 0;
                    transition: opacity 0.2s ease;
                    justify-content: center;
                    align-items: center;

                    button {
                        width: 24px;
                        height: 24px;
                        padding: 0;
                        border: none;
                        border-radius: 50%;
                        background: rgba(255, 255, 255, 0.9);
                        color: #333;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.2s ease;
                        z-index: 2;

                        svg {
                            width: 14px;
                            height: 14px;
                        }

                        &:hover {
                            transform: scale(1.1);
                            background: #fff;
                        }

                        &.remove-media:hover {
                            background: #ff4d4d;
                            color: #fff;
                        }

                        &.move-media:hover {
                            background: #4CAF50;
                            color: #fff;
                        }
                    }
                }

                &:hover .media-controls {
                    opacity: 1;
                }

                .media-order {
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    background: rgba(0, 0, 0, 0.6);
                    color: white;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: bold;
                    z-index: 3;
                }
            }

            .add-media-button {
                aspect-ratio: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #fff;
                border: 2px dashed #ddd;
                border-radius: 4px;
                cursor: pointer;
                transition: all 0.2s ease;
                gap: 4px;

                svg {
                    width: 20px;
                    height: 20px;
                    color: #666;
                }

                span {
                    font-size: 12px;
                    color: #666;
                    text-align: center;
                }

                &:hover {
                    border-color: #4CAF50;
                    background: rgba(76, 175, 80, 0.05);

                    svg, span {
                        color: #4CAF50;
                    }
                }
            }
        }

        // Adjust grid for smaller screens
        @media (max-width: 768px) {
            grid-template-columns: repeat(3, 1fr); // Show 3 items per row on mobile
            gap: 6px; // Reduce gap on mobile
        }

        @media (max-width: 480px) {
            grid-template-columns: repeat(2, 1fr); // Show 2 items per row on smaller devices
        }
    }

    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10; // Ensure it appears above other content
    }
}

// Remove or adjust these media queries if they're adding unwanted top padding
@media (min-width: 768px) {
    .profile-page {
        padding-top: 0; // Remove top padding
    }
}

@media (min-width: 1200px) {
    .profile-page {
        padding-top: 0; // Remove top padding
    }
}

// Add a media query to adjust positioning on smaller screens
@media (max-width: 1200px) {
    .profile-page {
        .profile-header {
            .settings-container {
                right: -300px; // Adjusted position for tablets
            }
        }
    }
}

// Mobile screens
@media (max-width: 767px) {
    .profile-page {
        .profile-header {
            .settings-container {
                right: -100px; // Mobile positioning
            }
        }
    }
}

// Add styles for the post actions
.post-actions {
    position: relative;

    .post-action-button {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        padding: 5px;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    .post-action-dropdown {
        position: absolute;
        bottom: 100%;
        right: 0;
        background: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(12px);
        border-radius: 12px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px) scale(0.95);
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        overflow: hidden;
        min-width: 130px;
        margin-bottom: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0) scale(1);
        }

        button {
            width: 100%;
            padding: 10px 12px;
            background: none;
            border: none;
            color: rgba(0, 0, 0, 0.8);
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            transition: all 0.15s ease;
            font-size: 14px;

            svg {
                font-size: 16px;
                opacity: 0.9;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }

            &:first-child {
                color: rgba(76, 175, 80, 0.9);
            }

            &:last-child {
                color: rgba(255, 77, 77, 0.9);
            }

            &:active {
                background-color: rgba(255, 255, 255, 0.4);
            }
        }

        &::before {
            content: '';
            position: absolute;
            bottom: -4px;
            right: 8px;
            width: 8px;
            height: 8px;
            background: rgba(255, 255, 255, 0.85);
            transform: rotate(45deg);
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(12px);
        }
    }
}

// Add styles for the edit form's media preview
.media-preview-section {
    .media-grid {
        .media-preview-item {
            // For edit mode, make items non-interactive
            &.edit-mode {
                pointer-events: none;
                
                .media-controls {
                    display: none;
                }
            }
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease;
}

.confirmation-modal {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(12px);
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 90%;
    width: 320px;
    animation: slideUp 0.3s ease;

    h3 {
        margin: 0 0 8px 0;
        color: rgba(0, 0, 0, 0.8);
        font-size: 18px;
        font-weight: 600;
    }

    p {
        margin: 0 0 20px 0;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
    }

    .confirmation-buttons {
        display: flex;
        gap: 12px;

        button {
            flex: 1;
            padding: 10px;
            border: none;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;

            &.cancel-button {
                background: rgba(0, 0, 0, 0.05);
                color: rgba(0, 0, 0, 0.7);

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }

            &.delete-button {
                background: rgba(255, 77, 77, 0.9);
                color: white;

                &:hover {
                    background: rgba(255, 77, 77, 1);
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

// Ensure inputs don't overflow
input, textarea {
    max-width: 100%;
    box-sizing: border-box;
}

// Add to ensure body doesn't scroll when modal is open
body.modal-open {
    overflow: hidden;
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.new-post-overlay {
    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: white;
        display: flex;
        align-items: stretch;

        .new-post-form {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 30px;
            border-radius: 0;
            max-width: none;
            max-height: none;
            display: flex;
            flex-direction: column;
            background-color: white;
            flex: 1;

            h2 {
                padding: 16px;
                margin: 0;
                border-bottom: 1px solid #eee;
                text-align: center;
            }

            form {
                height: calc(100% - 56px);
                display: flex;
                flex-direction: column;
                flex: 1;

                .form-content {
                    flex: 1;
                    overflow-y: auto;
                    padding: 0px;
                    padding-bottom: 80px;
                    width: 100%;
                    box-sizing: border-box;
                }

                .form-buttons {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 30px;
                    background: white;
                    border-top: 1px solid #eee;
                    margin: 0;
                    display: flex;
                    gap: 10px;
                    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
                    width: 100%;
                    box-sizing: border-box;

                    button {
                        flex: 1;
                        margin: 0;
                    }
                }
            }
        }
    }
}
