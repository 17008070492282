.follow-list-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.follow-list-modal {
    background-color: #1e1e1e;
    border-radius: 12px;
    padding: 24px;
    width: 340px;
    height: 80vh;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    position: relative;

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        color: #ffffff;
        font-size: 24px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #4CAF50;
        }
    }

    h2 {
        margin-bottom: 20px;
        text-align: center;
        font-size: 24px;
        color: #ffffff;
    }

    .follow-list {
        flex-grow: 1;
        overflow-y: auto;
        margin-bottom: 20px;
        padding-right: 10px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #2e2e2e;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #555;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #777;
        }

        .follow-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
            border-bottom: 1px solid #333;

            &:last-child {
                border-bottom: none;
            }

            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-right: 16px;
                object-fit: cover;
            }

            .username {
                flex-grow: 1;
                font-size: 16px;
                color: #ffffff;

                &.clickable {
                    cursor: pointer;
                    transition: color 0.3s ease;

                    &:hover {
                        color: #4CAF50;
                    }
                }
            }

            .follow-button {
                padding: 6px 12px;
                border-radius: 20px;
                font-size: 14px;
                font-weight: bold;
                cursor: pointer;
                transition: all 0.3s ease;
                background-color: #4CAF50;
                color: white;
                border: none;

                &.following {
                    background-color: transparent;
                    border: 1px solid #4CAF50;
                    color: #4CAF50;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}
