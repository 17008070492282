.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner {
        border-radius: 50%;
        border: 6px solid transparent;
        border-top-color: #FF6B6B;
        border-right-color: #4ECDC4;
        border-bottom-color: #FF6B6B;
        animation: spin 1s linear infinite;
        border-left-color: transparent;
    }

    &.small .spinner {
        width: 20px;
        height: 20px;
    }

    &.medium .spinner {
        width: 30px;
        height: 30px;
    }

    &.large .spinner {
        width: 40px;
        height: 40px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 