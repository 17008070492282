.likes-overlay {
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
}

.likes-list {
  position: absolute;
  top: 0;
  right: -300px;
  bottom: 0;
  width: 300px;
  background-color: #111;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateX(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;

  &.show {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  &.full-screen {
    position: absolute;
    z-index: 2;
  }

  @media (max-width: 768px) {
    &.full-screen {
      position: fixed;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      transform: translateY(100%);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      border-radius: 20px 20px 0 0;
      z-index: 10;

      &.show {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .likes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    h3 {
      margin: 0;
      color: #fff;
    }

    .close-button {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      padding: 5px;
      font-size: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  .likes-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;

    .no-likes-message {
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      padding: 20px;
      font-style: italic;
    }

    .like-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;

      .like-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }

      .like-username {
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
