.results-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 60px;

    @media (max-width: 1024px) {
        padding: 0;
    }

    .sticky-search-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(245, 245, 245, 0.95);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 10px 0;

        @media (max-width: 768px) {
            padding: 5px 10px;
            width: 100%;
            margin-top: 50px;
        }
    }

    .save-search-container {
        position: relative;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: 0 55px;
        margin-top: 10px;
        margin-bottom: 5px;

        @media (max-width: 1024px) {
            padding: 0 25px;
            margin-top: 8px;
            margin-bottom: 5px;
        }
    }

    .save-search-button {
        background-color: transparent;
        border: none;
        color: #57504e;
        cursor: pointer;
        padding: 8px 0;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;
        margin-left: auto;

        svg {
            font-size: 16px;
        }

        &:hover {
            color: #ffa500;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
                color: #57504e;
            }
        }

        @media (max-width: 768px) {
            padding: 6px 0;
            font-size: 12px;
            
            svg {
                font-size: 14px;
            }
        }
    }

    .search-bar-wrapper {
        position: relative;
        max-width: 600px;
        margin: 0 auto;

        @media (max-width: 768px) {
            margin: 10px 15px;
            width: auto;
        }
    }

    .results-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .results-actions {
        position: absolute;
        top: 0;
        right: 40px;
        z-index: 10;
        
        .save-search-button {
            background-color: #f0e6d9;
            border: 1px solid #d4c3a9;
            border-radius: 20px;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #8a7b76;
            transition: all 0.3s ease;

            &:hover {
                background-color: #e6d9c7;
                color: #6c5f5b;
            }

            svg {
                margin-right: 8px;
            }
        }
    }

    .loading-spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
    }
}

// Ensure ProductListPage takes up remaining space
.product-list-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media (max-width: 768px) {
        margin-top: 15px;
        
        .content-wrapper {
            padding: 10px;
        }
    }

    .content-wrapper {
        padding-top: 10px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 40px;
        box-sizing: border-box;
        overflow: hidden;

        @media (max-width: 1024px) {
            padding: 0 15px;
        }
    }
}

.modal-overlay-save-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;

    @media (max-width: 768px) {
        padding: 0 20px;
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}

.modal-save-search {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 165, 0, 0.1);
    text-align: center;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border: 1px solid rgba(0, 0, 0, 0.1);

    h2 {
        margin-top: 0;
        margin-bottom: 15px;
        color: #3a3a3a;
        font-size: 1.5rem;
    }

    .search-title-input {
        width: 90%;
        padding: 12px;
        margin-bottom: 15px;
        border: 1px solid #d4c3a9;
        border-radius: 6px;
        font-size: 16px;
        text-align: left;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .modal-actions {
        display: flex;
        justify-content: center;
        gap: 12px;
        margin-top: 10px;

        button {
            padding: 8px 16px;
            border: none;
            border-radius: 2px;
            cursor: pointer;
            font-size: 14px;
            min-width: 80px;

            &:first-child {
                background-color: #f5f5f5;
                color: #333;
            }

            &:last-child {
                background-color: #ffa500;
                color: white;
            }
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        max-width: 400px;
        margin: 0;
        background-color: white;
    }
}

// Ensure the content wrapper and save-search-container have the same max-width
.content-wrapper, .save-search-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

// Adjust masonry grid for mobile and iPad
.my-masonry-grid {
    display: flex;
    width: 100% !important;
    box-sizing: border-box;
    
    @media (max-width: 1024px) {
        margin-left: -8px !important;
    }
}

.my-masonry-grid_column {
    @media (max-width: 1024px) {
        padding-left: 8px !important;
    }
}

// Add this to ensure all elements respect border-box
* {
    box-sizing: border-box;
}

.load-more-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.load-more-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
    &:hover {
        background-color: #0056b3;
    }
}

.loading-indicator {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.results-summary {
    text-align: center;
    padding: 30px 0;
    color: #666;
    font-size: 14px;
    border-top: 1px solid #eee;
    margin-top: 20px;
    
    @media (max-width: 768px) {
        padding: 20px 15px;
        font-size: 13px;
    }
}
