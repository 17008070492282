.likes-page-container {
    min-height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    
    h1 {
        padding: 20px;
    }
}

.likes-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5; // Updated background color
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding-bottom: 20px;
    position: relative;

    .sticky-header-container {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: rgba(245, 245, 245, 0.8); // Updated to match CartPage
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 10px 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

        @media (max-width: 768px) {
            padding-top: 40px; // Add extra padding on mobile to prevent overlap
        }
    }

    .page-title {
        text-align: center;
        padding: 20px 0 10px;
        margin: 0;
        font-size: 28px;
        color: #3a3a3a;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;

        &::before {
            content: '❤️';  // Heart emoji for LikesPage
            font-size: 0.8em;
            margin-right: 10px;
            vertical-align: middle;
        }
    }

}

// Ensure ProductListPage takes up remaining space
.product-list-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}