.product-card {
  background: rgba(255, 255, 255, 0.8); // Slightly transparent white background
  border: 1px solid rgba(224, 216, 208, 0.5);
  border-radius: 16px;
  padding: 0;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #4a4a4a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
  backdrop-filter: blur(5px); // Adds a blur effect to the background
  
  &:hover {
    transform: scale(1.03);
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .image-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%; // 1:1 aspect ratio
    background-color: #f5f5f5;
    overflow: hidden;
  }

  .image-placeholder {
    width: 100%;
    padding-bottom: 100%; // Maintain aspect ratio
    background: #f0f0f0;
    position: relative;
    overflow: hidden;

    .shimmer-effect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        #f0f0f0 0%,
        #f8f8f8 50%,
        #f0f0f0 100%
      );
      background-size: 200% 100%;
      animation: shimmer 1.5s infinite;
    }
  }

  .product-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.loading {
      opacity: 0;
    }

    &.loaded {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }
  }

  .product-card-content {
    padding: 12px;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(245,240,234,0.8));
  }

  .product-card-name {
    font-size: 0.85em;
    font-weight: 600;
    margin: 0 0 4px;
    height: 2.8em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4em;
    color: #3a3a3a;

    .clickable-link {
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: #d3bb5d; // Yellowcolor on hover
      }
    }
  }

  .product-card-seller {
    font-size: 0.75em;
    font-weight: 500;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #8b7d6b;
  }

  .product-card-description,
  .product-card-price,
  .product-card-rating {
    display: none;
  }

  &:hover {
    .product-card-description,
    .product-card-price,
    .product-card-rating {
      display: block;
      font-size: 0.75em;
      margin: 4px 0;
      color: #4a4a4a;
    }
  }

  .product-card-icons {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;

    .product-card-icon {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      padding: 6px;
      font-size: 1.7em;
      cursor: pointer;
      transition: all 0.2s ease;
      backdrop-filter: blur(5px);

      &:hover {
        background: rgba(255, 255, 255, 1);
        transform: scale(1.1);
      }

      &.heart-icon.liked {
        color: red;
      }

      &.cart-icon.in-cart {
        color: green;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

// Add will-change to optimize performance
.my-masonry-grid_column {
  will-change: transform;
  transform: translateZ(0);
}

