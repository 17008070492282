.discover-page {
  position: relative;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
  overflow-x: hidden;

  .content-wrapper {
    position: relative;
    z-index: 2;
    margin-top: 80px;
    text-align: center;

    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .tab {
        background: none;
        border: none;
        font-size: 18px;
        font-weight: bold;
        color: #666;
        padding: 10px 20px;
        cursor: pointer;
        transition: color 0.3s ease;

        &.active {
          color: #3d3534;
          border-bottom: 2px solid #3d3534;
        }

        &:hover {
          color: #3d3534;
        }
      }
    }

    .posts-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 20px;
      padding: 20px;
      scroll-behavior: smooth; // This enables smooth scrolling

      @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
      }

      .post-card {
        position: relative;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease-in-out;
        aspect-ratio: 1 / 1;
        cursor: pointer;

        &:hover {
          transform: translateY(-5px);

          .post-overlay {
            opacity: 1;
          }
        }

        .post-image,
        .post-video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .post-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 10px;
          opacity: 0; // Hide by default
          transition: opacity 0.3s ease;

          @media (max-width: 768px) {
            opacity: 1; // Always show on mobile
          }

          .post-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            .post-user-info {
              display: flex;
              align-items: center;

              .post-user-avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
                object-fit: cover;
              }

              .post-username {
                font-weight: bold;
                font-size: 16px;
                color: #fff;
              }
            }

            .post-actions {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .like-button,
            .comment-button {
              background: none;
              border: none;
              color: #fff;
              font-size: 24px;
              margin-bottom: 10px;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;

              span {
                font-size: 14px;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }

    .search-container {
      position: relative;
      margin-bottom: 20px;
    }

    .search-form {
      display: flex;
      justify-content: center;

      input {
        width: 300px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 20px 0 0 20px;
        &:focus {
          outline: none;
          border-color: #4CAF50;
        }
      }

      button {
        padding: 10px 20px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 0 20px 20px 0;
        cursor: pointer;
      }
    }

    .search-dropdown {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      max-height: 400px;
      overflow-y: auto;

      .search-section {
        padding: 10px;

        h3 {
          font-size: 14px;
          color: #666;
          margin-bottom: 5px;
          position: sticky;
          top: 0;
          background-color: white;
          padding: 5px 0;
        }

        .search-item {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background-color: #f0f0f0;
          }

          .user-avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }

    .login-message {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      font-size: 18px;
      color: #666;

      a {
        color: #4CAF50;
        text-decoration: none;
        font-weight: bold;
        margin-right: 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.top-icons {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  color: #3d3534;
}

@media (min-width: 1200px) {
  .discover-page {
    .content-wrapper {
      max-width: 1400px;
      margin: 80px auto 0;
      padding: 0 20px;
    }
  }
}

.post-username, .comment-username {
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
}

