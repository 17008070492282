.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  display: flex;
  width: 90%;
  max-width: 1000px;
  height: 80vh;
  max-height: 600px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.post-display {
  flex: 1;
  position: relative;
  overflow: hidden;

  .post-image,
  .post-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .post-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  }

  .post-caption {
    color: #fff;
    margin-bottom: 20px;
    font-size: 14px;
    max-width: 80%;
  }

  .comment-toggle-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    span {
        margin-right: 5px;
        font-size: 14px;
      }
  }
}

.comment-section {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  background-color: #111;
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  &.full-screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background-color: #111;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;

    &.show {
      transform: translateX(0);
    }

    @media (max-width: 768px) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      transform: translateY(100%);
      transition: transform 0.3s ease-in-out;
      border-radius: 20px 20px 0 0;
      z-index: 10;

      &.show {
        transform: translateY(0);
      }
    }
  }

  &:not(.full-screen) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;

    &.show {
      transform: translateX(0);
    }
  }

  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    h3 {
      margin: 0;
      color: #fff;
    }

    .close-button {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      padding: 5px;
      font-size: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  .comments-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;

    .no-comments-message {
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      padding: 20px;
      font-style: italic;
    }

    .comment {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      .comment-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }

      .comment-content {
        flex: 1;

        .comment-username {
          font-weight: bold;
          margin: 0 0 5px;
        }

        .comment-text {
          margin: 0;
        }
      }
    }
  }

  .comment-form {
    display: flex;
    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    input {
      flex-grow: 1;
      margin-right: 10px;
      padding: 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      font-size: 16px;
      -webkit-appearance: none;
      appearance: none;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    button {
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #45a049;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-overlay {
    background-color: #000;
  }

  .modal-content {
    flex-direction: column;
    height: 100vh;
    max-height: none;
    width: 100%;
    max-width: none;
  }

  .post-display {
    flex: 1;
    aspect-ratio: auto;
  }

  .comment-section.full-screen {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50vh;
    border-radius: 20px 20px 0 0;

    .comment-header {
      padding: 20px;

      h3 {
        font-size: 18px;
      }

      .close-button {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 24px;
        padding: 8px;
      }
    }
  }
}

.comments-overlay {
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
}
