.cart-page-container {
  min-height: 100vh;
  background-color: #f5f5f5;;
  display: flex;
  flex-direction: column;
  
  h1 {
    padding: 20px;
  }
}

.cart-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5; // Updated background color
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
  position: relative;

  .sticky-header-container {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: rgba(245, 245, 245, 0.8); // Updated to match new background color
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      padding-top: 40px; // Add extra padding on mobile to prevent overlap
  }
  }

  .page-title {
    text-align: center;
    padding: 20px 0 10px;
    margin: 0;
    font-size: 28px;
    color: #3a3a3a;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;

    &::before {
      content: '🛒';
      font-size: 0.8em;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .cart-checkout-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 12px 24px;
    background-color: rgba(161, 56, 222, 0.53);
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #40c057;
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      background-color: rgba(161, 56, 222, 0.3);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }
  }

  // ... rest of the existing styles ...
}

// Ensure ProductListPage takes up remaining space
.product-list-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px; // Add some space below the sticky header
}