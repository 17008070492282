.cart-sidebar {
    width: 300px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    transform: translateX(100%);
    z-index: 1001; // Increased value
    color: #100f0f;
    
    &.visible {
        transform: translateX(0);
    }

    .close-icon, .go-to-cart {
        position: absolute;
        top: 20px;
        font-size: 22px;
        color: #161414;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #FFA500;
        }
    }

    .close-icon {
        left: 20px;
    }

    .go-to-cart {
        right: 20px;
    }

    .cart-title {
        text-align: center;
        font-size: 1.5rem;
        margin: 1rem 0;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #FFA500;
        }
    }

    .cart-items {
        flex-grow: 1;
        overflow-y: auto;
        padding: 15px;
    }

    .cart-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;  // Increased spacing between items
        padding: 15px;  // Increased padding
        border: 1px solid rgba(255, 255, 255, 0.3);  // Slightly more visible border
        border-radius: 12px;  // Increased border radius
        background-color: rgba(255, 255, 255, 0.34);  // Slightly more opaque background
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 165, 0, 0.1);  // Added shadow and subtle glow
        transition: all 0.3s ease;  // Smooth transition for hover effect

        &:hover {
            transform: translateY(-2px);  // Slight lift effect on hover
            box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 0 15px rgba(255, 165, 0, 0.2);  // Enhanced shadow on hover
        }

        .left-section {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 100px;

            .item-image {
                width: 100%;
                height: auto;
                margin-bottom: 0.5rem;
                border-radius: 8px;  // Rounded corners for images
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  // Subtle shadow for images
            }

            .item-actions {
                display: flex;
                justify-content: space-around;
                width: 100%;

                .like-icon, .delete-icon {
                    font-size: 1rem;
                    color: #FFA500;
                }
            }
        }

        .right-section {
            flex: 2;
            padding-left: 1rem;

            .item-name {
                font-size: 1.1rem;  // Slightly larger font
                font-weight: 600;  // Bolder font
                margin: 0 0 0.5rem;  // Added bottom margin
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .item-seller, .item-price, .item-rating {
                margin: 0.35rem 0;  // Increased vertical spacing
                font-size: 0.9rem;  // Slightly smaller font for details
            }

            .item-price {
                font-weight: 600;  // Bolder price
                // color: #FFA500;  // Highlighted price with theme color
            }

            .item-rating {
                display: flex;
                align-items: center;

                span {
                    margin-right: 0.25rem;
                }

                &::after {
                    content: '★';
                    color: #FFA500;  // Theme color for star
                }
            }
        }
    }

    .checkout-button {
        background-color: rgba(161, 56, 222, 0.53);
        color: #ffffff;
        padding: 15px;
        border: none;
        cursor: pointer;
        width: 90%;

        align-self: center;
        font-size: 18px;
        position: sticky;
        bottom: 15px;
        text-align: center;
        border-radius: 20px;

        &:hover {
            background-color: #FFA500;
        }
    }
}
