/* Base Styles */
.landing-page {
    position: relative;
    min-height: 100vh;
    padding: 20px;
    background-image: url('../public/splash.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(101, 99, 99, 0.4); // Slightly darker overlay
        z-index: 1;
    }
}

/* Sidebar Toggle Button */
.toggle-sidebar-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;
    color: #ffffff; // Changed back to white for visibility
}

/* Content Wrapper */
.content-wrapper {
    position: relative;
    z-index: 2;
    margin-top: 60px;
    text-align: center;

    .heading {
        font-size: 36px;
        margin-bottom: 20px;
        color: #ffffff;
        
        @media (max-width: 768px) {
            font-size: 28px; // Smaller font on mobile
            padding: 0 15px; // Add some padding on sides
            
            .rotating-word {
                width: 90px; // Smaller width for mobile
                display: block; // Stack the rotating word below
                margin: 10px auto; // Center it
            }
        }
        
        .rotating-word {
            display: inline-block;
            width: 120px; /* Adjust this width based on the longest word */
            text-align: left;
            animation: rotateWord 5s infinite;
            transform-origin: 50% 50%;
            backface-visibility: hidden;
            perspective: 1000px;
            color: #D0D0D0;
        }
    }

    .search-box-wrapper {
        display: inline-flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 10px 20px;
        border-radius: 50px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(5px);
        
        @media (max-width: 768px) {
            width: 85%; // Take up most of the screen width
            padding: 8px 15px; // Slightly smaller padding
            
            .search-box {
                width: calc(100% - 30px); // Full width minus arrow icon space
                font-size: 14px; // Slightly smaller font
            }
        }

        .search-box {
            border: none;
            outline: none;
            font-size: 16px;
            width: 300px;
            background: transparent;
            

            &::placeholder {
                color: rgba(255, 255, 255, 0.7); // Lighter placeholder text
            }
        }

        .arrow-icon {
            margin-left: 10px;
            cursor: pointer;
            color: #ffffff; // Changed back to white
            transition: transform 0.2s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

@keyframes rotateWord {
    0%, 20% { opacity: 0; transform: translateY(20px); }
    25%, 95% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-20px); }
}

// Ensure TopIcons are visible
.top-icons {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
    color: #ffffff; // Changed back to white for visibility
}

.loading-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    .loading-spinner {
        transform: scale(1.5); // Keep the spinner larger
    }
}


